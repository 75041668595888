import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../components/wrapper";
import HeaderTitle from "../../components/header_title";

const About = () => {
  return (
    <div className={s.about}>
      <Wrapper>
        <HeaderTitle/>
        <br/>
        <p className={s.text}>Чтобы посмотреть предложения по займам - перейдите на главную страницу.</p>
        <p className={s.text}>Сайт не осуществляет выдачу кредитов и займов, носит чисто информационный характер.</p>
        <p className={s.text}>Здесь собраны максимально выгодные предложения с удобными фильтрами.</p>
        <p className={s.text}>Мы работаем на благо клиента и не берем с пользователей деньги.</p>
        <p className={s.text}>Все рекомендуемые на сайте кредитные учреждения имеют соответствующие лицензии.</p>
        <p className={s.text}>
          {/*<div><b>samzaim.ru</b></div>*/}
          {/*<div>Бондаренко Михаил Николаевич. Беларусь, Гомельская область, Калинковичи, улица 40 лет Победы, 34.</div>*/}
          {/*<div>email: admin@samzaim.ru, ИНН: EB1199290, телефон: +375 293398694</div>*/}
        </p>
      </Wrapper>
    </div>
  );
};

export default About;
