import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../components/wrapper";
import HeaderTitle from "../../components/header_title";

const Advice = () => {
  return (
    <div className={s.advice}>
      <Wrapper>
        <HeaderTitle/>
        <br/>
        <h2>Что делать если не одобрили займ?</h2>
        <p className={s.text}>Попробуйте уменьшить запрошенную сумму и увеличить срок кредитования. К примеру, если вы брали займ на 15 000 руб. и вам отказали, то нужно разделить эту сумму на 2-3 части, т.е. взять по 5000 руб., в разных МФО, одновременно подавая заявку.</p>
        <h2>Как увеличить вероятность одобрения</h2>
        <p className={s.text}>Мы рекоменудем вам подавать заявку одновременно в нескольких компаниях. Кредитным специалистам важно и то, насколько клиент интересуется процентными ставками, графиком погашения и общей переплатой по займу. Ваши заявки, задолженности, активные займы фиксируются, поэтому необходимо подавать заявку сразу, чтобы данные не успели обновится.</p>
        <h2>Если вам не одобрила ни одна организация</h2>
        <p className={s.text}>Нужно улучшать кредитную историю. Погасите все имеющиеся задолженности, Переждите определенное время и попробуйте несколько раз взять минимальные займы в организациях где вы не брали деньги.</p>
        <h2>Если не успеваете погасить вовремя</h2>
        <p className={s.text}>Оплатите проценты и продлите свой заем - иначе проценты будут очень большие. Чтобы этого не произошло, необходимо сразу же сообщить сотрудникам кредитной фирмы о своих финансовых трудностях. В этом случае возможно, что компания пойдет вам навстречу и оформит пролонгацию кредитного договора.</p>
        <h2>Последствия невыплаты заёмных средств</h2>
        <p className={s.text}>В случае невозвращения общей суммы кредита кредитор вынуждено начислит штраф за просрочку. Большинство кредиторов идут на уступки и дают дополнительные 3 рабочих дня для оплаты. Они предусмотрены специально для Вас на случай, к примеру, если банковский перевод занял больше времени, чем обычно, или Вы просто поздно отправили сумму. Однако в случае неполучения от Вас какой-либо реакции в течение продолжительного времени будет начислен штраф за просрочку погашения кредита размером в среднем 0,10% от первоначальной суммы кредита, данные о Вас могут быть переданы в реестр должников, а задолженность - коллекторскому агентству для взыскания долга.
          О всех приближающихся сроках платежа кредитор своевременно информирует Вас по СМС и электронной почте. Рекомендуем Вам вносить платеж в день получения данных напоминаний. Так Вы сохраните репутацию добросовестного заемщика и сможете получить скидки при последующем обращении в организацию.
          Предложение не является оффертой. Конечные условия уточняйте при прямом общении с кредиторами.</p>
      </Wrapper>
    </div>
  );
};

export default Advice;
