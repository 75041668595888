import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../components/wrapper";
import HeaderTitle from "../../components/header_title";

const Conditions = () => {
  return (
    <div className={s.about}>
      <Wrapper>
        <HeaderTitle/>
        <h2>Требования к заёмщикам</h2>
        <p className={s.text}>Гражданство Российской Федерации.</p>
        <p className={s.text}>Возраст от 18 лет (или от 21 года для некоторых сервисов).</p>
      </Wrapper>
    </div>
  );
};

export default Conditions;
