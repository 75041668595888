import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Header from "./header";
import axios from "axios";
import ContentMain from "./content";

const Main = () => {
    const [headerData, setHeaderData] = useState('')

    const getHeaderData = async () => {
        try {
            const res = await axios.get('/api/credits?site=samzaim.ru&promo=true')
            setHeaderData(res.data.ok === true && res.data.result?.length ? res.data.result[0] : null)
        } catch (e) {
            setHeaderData(null)
        }
    }

    useEffect(() => {
        getHeaderData()
    }, [])

    return (
        <div className={s.main}>
            {headerData !== null && <Header headerData={headerData}/>}
            <ContentMain/>
        </div>
    );
};

export default Main;
